import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import dayjs from "dayjs";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import UserServices from "../../services/UserServices";
import { ToastContainer, toast } from "react-toastify";

function Docread(prop) {
  const [readshow, setreadshow] = useState(false);
  const readHandleClose = () => setreadshow(false);
  const readHandleShow = () => setreadshow(true);
  console.log("downloading file:", prop.data.id);
  //const url = prop.doc[0].documentUrl;
  // console.log(prop.doc[0].documentUrl)
  const data = prop.data.orderStatus;

  const handleDownload = async (id, documentUrl) => {
    debugger;
    // const fileType = documentUrl.split(".")[4];
    const fileType = documentUrl.split(".").pop();

    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();
      console.error("downloading file:", documentUrl.split("."));
      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Order-${id}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const [fileshow, setFileshow] = useState(false);
  const [shippedDocList, setShippedDocList] = useState([]);
  const [shippedDocname, setShippedDocname] = useState();
  const [shippedDocdate, setShippedDocdate] = useState();

  const [fileCount, setFileCount] = useState(0);
  const filehandleClose = () => setFileshow(false);
  const filehandleShow = (doc, name, date) => {
    setFileshow(true);
    setShippedDocList(doc);
    setShippedDocname(name);
    setShippedDocdate(date);
  };

  //function to upload file for specific order
  console.log("doc_URL", prop.documentUrl);

  const handleUploadFile = (e) => {
    debugger;
    const id = prop.data.id;
    const file = e.target.files[0]; // getting the file which is being uploaded

    try {
      console.log("fileInput", file);
      console.log("order_id", id);
      UserServices.uploadOrderFile(id, file)
        .then((response) => {
          if (response.status === 200) {
            toast.success("File uploaded successfully");
            prop.getFunction();
          } else {
            toast.error("Error while uploading file");
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          toast.error("Error while uploading file");
        });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error while uploading file");
    }
  };

  const handleDownloads = async (name, fileType, documentUrl) => {
    //const fileType = documentUrl.split(".")[4]
    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();
      console.error("downloading file:", documentUrl.split("."));
      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={readHandleShow}
      >
        {" "}
        <i className="bi bi-eye"></i>
      </button>
      <Modal
        show={readshow}
        size="lg"
        onHide={readHandleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>#{prop.data.id} Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Control
                  value={prop.data.userPrincipal.name}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Payment Terms</b>
                </Form.Label>

                <Form.Control
                  value={prop.data.paymentTerms}
                  disabled
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Performa Invoice</b>
                </Form.Label>

                <Form.Control value={prop.data.invoice} disabled></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Inco Term</b>
                </Form.Label>
                <Form.Control
                  value={prop.data.incoTerms}
                  disabled
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Insurance</b>
                </Form.Label>
                <Form.Control
                  value={prop.data.insurance}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Freight</b>
                </Form.Label>
                <Form.Control value={prop.data.freight} disabled></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Special Requirements</b>
                  </Form.Label>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: prop.data.specialRequirement,
                    }}
                    style={{
                      maxWidth: "800px", // Set maximum width
                      maxHeight: "200px", // Set maximum height
                      overflowY: "auto", // Enable vertical scrolling
                      border: "1px solid #ccc", // Add a border for clarity
                      padding: "5px", // Add padding for aesthetics
                      wordWrap: "break-word", // Allow word wrapping
                      marginBottom: "15px",
                    }}
                  ></div>

                  <div className="file-input">
                    <label className="file-input__label" for="file-input">
                      <input
                        type="file"
                        id="multi-file-input"
                        class="file-input__input"
                        onChange={handleUploadFile}
                      />
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="upload"
                        class="svg-inline--fa fa-upload fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                        ></path>
                      </svg>
                      <span>Upload file</span>
                    </label>
                  </div>
                  <br />
                  <div
                    className="files"
                    id="fileList"
                    style={
                      fileCount > 0 ? { display: "block" } : { display: "none" }
                    }
                  >
                    <h5>Files Selected</h5>
                    <ul className="Filelisting"></ul>
                  </div>

                  {prop?.data?.productDocumentation?.length > 0 && (
                    <div>
                      <p>
                        <Form.Label>
                          <b>Uploaded Documents</b>
                        </Form.Label>
                      </p>
                      <span
                        onClick={() =>
                          handleDownload(
                            prop?.data?.id,
                            prop?.data?.productDocumentation[0]?.documentUrl
                          )
                        }
                      >
                        <img
                          src="/assets/images/pdf.png" //changed
                          className="download"
                          width="40px"
                          height="auto"
                          alt=""
                        />
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mt-3">
                <Form.Label>
                  <b>Track Status</b>
                </Form.Label>
                <div class="history-tl-container">
                  <ul class=" tl virtical">
                    {data?.map((item) => (
                      <li class="tl-item" ng-repeat="item in retailer_history">
                        <div class="timestamp">
                          {item.order_Status_Enum == "SHIPPED" ? (
                            <span
                              onClick={() =>
                                filehandleShow(
                                  item.shippedDocumentations,
                                  item.order_Status_Enum,
                                  item.updatedAt
                                )
                              }
                            >
                              <i class="fa fa-files-o"></i>{" "}
                              {item.shippedDocumentations.length} Files
                            </span>
                          ) : null}
                          {dayjs(item.updatedAt).format("DD MMM YYYY")}
                          <br /> {dayjs(item.updatedAt).format("hh : mm A")}
                        </div>
                        <div class="item-title">{item.order_Status_Enum}</div>
                        <div class="item-detail">{item.comment}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={readHandleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={fileshow}
        onHide={filehandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="shippedDocList"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {shippedDocname} - {dayjs(shippedDocdate).format("DD MMM YYYY")} -{" "}
            {dayjs(shippedDocdate).format("hh : mm A")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="orderShipList">
            {shippedDocList.length > 0 ? (
              shippedDocList.map((item) => (
                <li>
                  <div className="filename">
                    {item.name}.{item.fileType}
                  </div>
                  <button
                    onClick={(e) =>
                      handleDownloads(
                        item.name,
                        item.fileType,
                        item.documentUrl
                      )
                    }
                  >
                    <img src="/assets/images/download.png" />
                  </button>
                </li>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "150px",
                }}
              >
                <img
                  src="/assets/images/no-document.png"
                  style={{ width: "100px" }}
                />
                <p style={{ margin: 0 }}>
                  <b>No Documents</b>
                </p>
              </div>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={filehandleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Docread;
