import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import dayjs from "dayjs";
import { Form } from "react-bootstrap";
import UserServices from "../../services/UserServices";
import { Chip, Portal, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function OrderStatus(prop) {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = prop.data;
  // param.row.orderStatus[param.row.orderStatus.length - 1]
  const dataLength = data.length - 1;
  const lastUpdate = prop?.data[dataLength]?.order_Status_Enum;

  const [statusValue, setStatusValue] = useState("");
  const [comment, setComment] = useState("");
  let dataJson = {
    comment: comment,
    order_Status_Enum: statusValue,
  };
  function orderStatus() {
    UserServices.dcNoteStatusupdate(dataJson, prop.id)
      .then((response) => {
        console.log(response);
        setMessage("D/C Notes status changed successfully");
        setOpen(true);
        setSeverity("success");
        prop.load();
      })
      .catch((error) => {
        setMessage("Something went wrong!");
        setOpen(true);
        setSeverity("error");
      });
  }
  const colors =
    lastUpdate == "PENDING"
      ? "#FF7272"
      : lastUpdate == "APPROVED"
      ? "#4CAF50 "
      : lastUpdate == "DELIVERED"
      ? "#45A049"
      : lastUpdate == "SHIPPED"
      ? "#3498DB"
      : lastUpdate == "REJECTED"
      ? "#dc3545"
      : lastUpdate == "PRODUCTION"
      ? "#B77E00"
      : null;
  // console.log("OrderStatus DD", lastUpdate, colors);
  return (
    <>
      <Chip
        label={lastUpdate == undefined ? "PENDING" : lastUpdate}
        // color={`${colors.toString()}`}
        onClick={handleShow}
        style={{ width: "100%", backgroundColor: colors, color: "#fff" }}
      />
      {/* <Button
        variant="primary"
        onClick={handleShow}
        className="btn btn-primary mx-1"
      >
        <i className="bi bi-pencil"></i>
        {lastUpdate}
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Debit/Credit Notes Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="history-tl-container">
            {prop.role == "ROLE_CLIENT" || prop.role == "ROLE_SUB_ADMIN" ? (
              <>
                <Form.Select
                  onChange={(e) => setStatusValue(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="">Please select D/C Current Status</option>
                  <option value="REJECTED">Reject</option>
                  <option value="APPROVED">Approved</option>
                </Form.Select>
                <Form.Control
                  className="mt-2"
                  placeholder="Enter Comment Here"
                  as="textarea"
                  style={{ height: "100px" }}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={statusValue == ""}
                ></Form.Control>
                <Button
                  className="btn btn-dark w-100 mt-2"
                  onClick={orderStatus}
                  disabled={statusValue == ""}
                >
                  Update
                </Button>
                <hr />
              </>
            ) : null}
            {prop.role == "ROLE_ADMIN" ? (
              <>
                <Form.Select
                  onChange={(e) => setStatusValue(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="PENDING">Select Status</option>
                  <option value="REJECTED">Reject</option>
                </Form.Select>
                <Form.Control
                  className="mt-2"
                  placeholder="Enter Comment Here"
                  as="textarea"
                  style={{ height: "100px" }}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={statusValue == ""}
                ></Form.Control>
                <Button
                  className="btn btn-dark w-100 mt-2"
                  onClick={orderStatus}
                  disabled={statusValue == ""}
                >
                  Update
                </Button>
                <hr />
              </>
            ) : null}
            <p>{lastUpdate == undefined ? " There no any Action" : null}</p>
            <ul class=" tl">
              {data?.map((item) => (
                <li class="tl-item" ng-repeat="item in retailer_history">
                  <div class="timestamp">
                    {dayjs(item.updatedAt).format("DD MMM YYYY")}
                    <br /> {dayjs(item.updatedAt).format("hh : mm A")}
                  </div>
                  <div class="item-title">{item.order_Status_Enum}</div>
                  <div class="item-detail">{item.comment}</div>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Portal>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ zIndex: 1500 }} // Ensure Snackbar appears above the modal
        >
          <Alert
            onClose={alertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}

export default OrderStatus;
