import http from "../httpcommon";
import authHeader from "./auth-header";

const getAllUsers = (page, pageSize, roleVeiw) => {
  return http.get(
    `/api/users/search1?role=${roleVeiw}&pageNumber=${page}&size=${pageSize}&sortBy=Id&sortDir=desc`
  );
};
const adduser = (data) => {
  return http.post(`/api/users/`, data);
};
const updateUser = (data, id) => {
  data.countryId = parseInt(data.countryId);
  return http.put(`/api/users/${id}`, data);
};
const deleteUser = (deleteId) => {
  console.log("deleteId", deleteId);
  return http.delete(`/api/users/${deleteId}`);
};
const userBlock = (data, id) => {
  console.log("userBlock", data, id);
  return http.put(`api/users/status/${id}?status=${data}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const galleryGet = (
  page,
  pageSize,
  categoriesId,
  name,
  code,
  principalFilter
) => {
  console.log("getUsers galleryGet", categoriesId, name, code);
  return http.get(
    `api/product/new?subCategoryId=${categoriesId}&name=${name}&productCode=${code}&principalId=${principalFilter}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=dsc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const galleryGetPrincipal = (page, pageSize) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    // `api/principle/product/?pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `api/all?pageNumber=${page}&pageSize=${pageSize}`,
    {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
const categoriesGet = () => {
  return http.get(`api/categories/`);
};
const galleryPost = (data) => {
  //debugger;

  return http.post(`/api/save/product`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const updateGalleryPost = (data, id) => {
  //debugger;

  return http.put(`/api/update/product/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const techDocDelete = (orderId, fileId) => {
  return http.delete(`order/file/${orderId}/${fileId}`);
};

const techDocGalleryDelete = (productId, docId) => {
  return http.delete(`/api/delete/${productId}/document/${docId}`);
};
const deleteProduct = (id) => {
  return http.delete(`/api/product/${id}`);
};

const addTechDoc = (data, onUploadProgress) => {
  return http.post(`api/upload/techinicalData`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const getCurrencyList = () => {
  return http.get(`/admin/currencies/list`);
};

const getProductDocCategory = () => {
  return http.get(`/doc/product/categories`);
};

const orderGet = (
  page,
  pageSize,
  orderStatus,
  orderIdFilter,
  principalFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `order/orders?status=${status}&currentOrderStatus=${orderStatus}&orderId=${orderIdFilter}&principalId=${principalFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const sampleOrderGet = (
  page,
  pageSize,
  orderStatus,
  sampleIdFilter,
  principalFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `sample/distributor?status=${status}&currentSampleStatus=${orderStatus}&sampleId=${sampleIdFilter}&principalId=${principalFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const newOrder = (data) => {
  return http.post(`order/save/order`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const newSampleOrder = (data) => {
  return http.post(`sample/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const updateOrder = (data, id) => {
  debugger;
  // console.log(JSON.stringify(data));
  // console.log("Id_to_update", id);
  // const formData = new FormData();
  // formData.append("files", data);
  console.log("data", data);

  return http.put(`order/update/order/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateSampleOrder = (data, id) => {
  debugger;
  return http.put(`sample/update/sample/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const searchPrinciple = (page, pageSize) => {
  return http.get(`api/users/searchPrinciple?role=3&name=p`);
};

const adminOrderGet = (
  page,
  pageSize,
  orderStatus,
  orderIdFilter,
  principalFilter,
  distributerFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", startDate, endDate);
  return http.get(
    `order/orders/Admin?status=${status}&currentOrderStatus=${orderStatus}&principalId=${principalFilter}&distributorId=${distributerFilter}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const subadminOrderGet = (
  page,
  pageSize,
  orderStatus,
  orderIdFilter,
  principalFilter,
  distributerFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", startDate, endDate);
  return http.get(
    `order/orders/Manager?status=${status}&currentOrderStatus=${orderStatus}&principalId=${principalFilter}&distributorId=${distributerFilter}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const adminPaymentGet = (
  page,
  pageSize,
  orderStatus,
  paymentId,
  distributerFilter,
  principalFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("adminPaymentGet", startDate, endDate);
  return http.get(
    `payment/admin?currentPaymentStatus=${orderStatus}&paymentId=${paymentId}&distributorId=${distributerFilter}&principalId=${principalFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const subAdminPaymentGet = (
  page,
  pageSize,
  orderStatus,
  paymentId,
  distributerFilter,
  principalFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("adminPaymentGet", startDate, endDate);
  return http.get(
    `payment/manager?currentPaymentStatus=${orderStatus}&paymentId=${paymentId}&distributorId=${distributerFilter}&principalId=${principalFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const paymentApproved = (id, data) => {
  console.log("orderApproved", data, id);
  return http.put(`payment/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const approvedPaymentPrincipal = (
  page,
  pageSize,
  orderStatus,
  paymentId,
  distributerFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  return http.get(
    `payment/approvedbyadmin?currentPaymentStatus=${orderStatus}&paymentId=${paymentId}&distributorId=${distributerFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const paymentDistributor = (
  page,
  pageSize,
  orderStatus,
  paymentId,
  principalFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `payment/distributor?currentPaymentStatus=${orderStatus}&paymentId=${paymentId}&principalId=${principalFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const newPayment = (data) => {
  return http.post(`payment/save/payment`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const updatePayment = (data, id) => {
  return http.put(`payment/update/payment/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const deletePayment = (id) => {
  return http.delete(`payment/${id}`);
};

const PaymentStatusupdate = (data, id) => {
  return http.put(`payment/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};

const complaintDistributor = (
  page,
  pageSize,
  orderStatus,
  complaintId,
  principalFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `complaint/distributor?currentComplaintStatus=${orderStatus}&complaintId=${complaintId}&principalId=${principalFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const complaintAdmin = (
  page,
  pageSize,
  orderStatus,
  complaintId,
  principalFilter,
  distributerFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `complaint/admin?currentComplaintStatus=${orderStatus}&complaintId=${complaintId}&principalId=${principalFilter}&distributorId=${distributerFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const subComplaintAdmin = (
  page,
  pageSize,
  orderStatus,
  complaintId,
  principalFilter,
  distributerFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `complaint/manager?currentComplaintStatus=${orderStatus}&complaintId=${complaintId}&principalId=${principalFilter}&distributorId=${distributerFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const complaintPrinciple = (
  page,
  pageSize,
  orderStatus,
  complaintId,
  distributerFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `complaint/approvedbyadmin?currentComplaintStatus=${orderStatus}&complaintId=${complaintId}&distributerId=${distributerFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const newComplaint = (data) => {
  return http.post(`complaint/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const updateComplaint = (data, id) => {
  return http.put(`complaint/update/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const complaintApproved = (id, data) => {
  console.log("orderApproved", data, id);
  return http.put(`complaint/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const complaintStatusupdate = (data, id) => {
  debugger;
  return http.put(`complaint/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const deleteComplaint = (id) => {
  return http.delete(`complaint/${id}`);
};

const technicalEnquiryDistributor = (
  page,
  pageSize,
  queryId,
  principalFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    //`technicalquery/distributor?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `technicalquery/distributor?queryId=${queryId}&principalId=${principalFilter}&recentModified=${recentModified}&currentTechQueryStatus=${orderStatus}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const technicalEnquiryAdmin = (
  page,
  pageSize,
  queryId,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    //`technicalquery/admin?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `technicalquery/admin?queryId=${queryId}&principalId=${principalFilter}&distributorId=${distributerFilter}&recentModified=${recentModified}&currentTechQueryStatus=${orderStatus}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const technicalEnquirySubAdmin = (
  page,
  pageSize,
  queryId,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    //`technicalquery/admin?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `technicalquery/manager?queryId=${queryId}&principalId=${principalFilter}&distributorId=${distributerFilter}&recentModified=${recentModified}&currentTechQueryStatus=${orderStatus}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const technicalEnquiryPrinciple = (
  page,
  pageSize,
  queryId,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    //`technicalquery/approvedbyadmin?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `technicalquery/approvedbyadmin?queryId=${queryId}&distributorId=${distributerFilter}&recentModified=${recentModified}&currentTechQueryStatus=${orderStatus}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const technicalEnquiryApproved = (id, data) => {
  console.log("orderApproved", data, id);
  return http.put(`technicalquery/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const technicalEnquiryUpdate = (data, id) => {
  return http.put(`technicalquery/update/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const technicalEnquiryDelete = (id) => {
  return http.delete(`technicalquery/${id}`);
};

const technicalEnquiryStatusupdate = (data, id) => {
  return http.put(`technicalquery/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const dcNoteDistributor = (
  page,
  pageSize,
  orderStatus,
  dcNoteId,
  principalFilter,
  recentModified,
  status,
  startDate,
  endDate
) => {
  return http.get(
    `dcNotes/distributor?currentDcNotesStatus=${orderStatus}&dcNotesId=${dcNoteId}&principalId=${principalFilter}&recentModified=${recentModified}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const dcNoteAdmin = (
  page,
  pageSize,
  dcNoteId,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    `dcNotes/admin?dcNotesId=${dcNoteId}&principalId=${principalFilter}&distributorId=${distributerFilter}&recentModified=${recentModified}&currentDcNotesStatus=${orderStatus}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const dcNoteSubAdmin = (
  page,
  pageSize,
  dcNoteId,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    `dcNotes/manager?dcNotesId=${dcNoteId}&principalId=${principalFilter}&distributorId=${distributerFilter}&recentModified=${recentModified}&currentDcNotesStatus=${orderStatus}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const dcNotePrinciple = (
  page,
  pageSize,
  dcNoteId,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    `dcNotes/approvedbyadmin?dcNotesId=${dcNoteId}&distributorId=${distributerFilter}&recentModified=${recentModified}&currentDcNotesStatus=${orderStatus}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const dcNoteSave = (data, id) => {
  return http.post(`dcNotes/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

const dcNoteUpdate = (data, id) => {
  return http.put(`dcNotes/update/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const dcNotedelete = (id) => {
  return http.delete(`dcNotes/${id}`);
};
const dcNoteApproved = (data, id) => {
  return http.put(`dcNotes/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

const dcNoteStatusupdate = (data, id) => {
  return http.put(`dcNotes/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};

const salesPipelineStatusUpdate = (data, id) => {
  debugger;
  console.log("data_Status_update", data);
  return http.put(`sales/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};

const salesPipelineDistributor = (
  page,
  pageSize,
  saleId,
  principalFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  return http.get(
    // `sales/distributor?salesId=${saleId}&principalId=${principalFilter}&recentModified=${recentModified}&status=${status}&currentSalesStatus=${orderStatus}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `sales/distributor?salesId=${saleId}&principalId=${principalFilter}&recentModified=${recentModified}&status=${status}&currentSalesStatus=${orderStatus}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const salesPipelinePrinciple = (
  page,
  pageSize,
  saleId,
  distributerFilter,
  recentModified,
  status,
  startDate,
  endDate
) => {
  return http.get(
    //`sales/approvedbyadmin?salesId=${saleId}&distributorId=${distributerFilter}&recentModified=${recentModified}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `salesDoc/principal`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const salesPipelineSave = (data, id) => {
  return http.post(`sales/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const salesPipelineUpdate = (data, id) => {
  return http.put(`sales/update/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const salesPipelineAdmin = (
  page,
  pageSize,
  saleId,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `sales/admin?salesId=${saleId}&principalId=${principalFilter}&distributorId=${distributerFilter}&recentModified=${recentModified}&status=${status}&currentSalesStatus=${orderStatus}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const salesPipelineSubAdmin = (
  page,
  pageSize,
  saleId,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `sales/manager?salesId=${saleId}&principalId=${principalFilter}&distributorId=${distributerFilter}&recentModified=${recentModified}&status=${status}&currentSalesStatus=${orderStatus}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const salesPipelineApproved = (data, id) => {
  return http.put(`sales/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
//services to upload doc/forecast page
const salesPipelineExcelUpload = (data) => {
  return http.post(`salesDoc/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const budgetDistributor = (
  page,
  pageSize,
  orderIdFilter,
  principalFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    //`technicalquery/distributor?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `budget/distributor?status=${status}&principalId=${principalFilter}&budgetId=${orderIdFilter}&currentBudgetStatus=${orderStatus}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const newBudget = (data) => {
  debugger;
  return http.post(`budget/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const budgetAdmin = (
  page,
  pageSize,
  orderIdFilter,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    //`technicalquery/distributor?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `budget/admin?status=${status}&principalId=${principalFilter}&distributorId=${distributerFilter}&budgetId=${orderIdFilter}&currentBudgetStatus=${orderStatus}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const budgetSubAdmin = (
  page,
  pageSize,
  orderIdFilter,
  principalFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    //`technicalquery/distributor?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `budget/manager?status=${status}&principalId=${principalFilter}&distributorId=${distributerFilter}&budgetId=${orderIdFilter}&currentBudgetStatus=${orderStatus}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const budgetPrinciple = (
  page,
  pageSize,
  orderIdFilter,
  distributerFilter,
  recentModified,
  orderStatus,
  status,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    //`technicalquery/distributor?status=${status}&complaintId=${orderStatus}&principalName=${""}&orderId=${orderIdFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    //  `budget/approvedbyadmin?status=${status}&principalId=${distributerFilter}&budgetId=${orderIdFilter}&currentBudgetStatus=${orderStatus}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `budgetDoc/principal`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const BudgetUpdate = (data, id) => {
  return http.put(`budget/update/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const deleteBudget = (id) => {
  return http.delete(`budget/${id}`);
};
const budgetApproved = (id, data) => {
  console.log("orderApproved", data, id);
  return http.put(`budget/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const budgetStatusupdate = (data, id) => {
  return http.put(`budget/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};
const budgetExcelDownload = (principalFilter) => {
  return http.get(`budget/export-to-excel/${principalFilter}`, {
    headers: {
      // ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};
//services to upload doc/ budget page
const budgetExcelUpload = (data) => {
  return http.post(`budgetDoc/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

//services to upload doc/forecast page
const forecastExcelUpload = (data) => {
  return http.post(`forecastDoc/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

const adminSampleOrderGet = (
  page,
  pageSize,
  orderStatus,
  sampleIdFilter,
  principalFilter,
  distributerFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `sample/admin?status=${status}&currentSampleStatus=${orderStatus}&sampleId=${sampleIdFilter}&principalId=${principalFilter}&distributorId=${distributerFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const subAdminSampleOrderGet = (
  page,
  pageSize,
  orderStatus,
  sampleIdFilter,
  principalFilter,
  distributerFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `sample/manager?status=${status}&currentSampleStatus=${orderStatus}&sampleId=${sampleIdFilter}&principalId=${principalFilter}&distributorId=${distributerFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const orderApproved = (data, id) => {
  console.log("orderApproved", data, id);
  return http.put(`order/orderApproved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const sampleOrderApproved = (data, id) => {
  return http.put(`sample/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const approvedOrderPrincipal = (
  page,
  pageSize,
  orderStatus,
  orderIdFilter,
  distributerFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `order/orders/ApprovedByAdmin?status=${status}&currentOrderStatus=${orderStatus}&orderId=${orderIdFilter}&distributorId=${distributerFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const approvedSampleOrderPrincipal = (
  page,
  pageSize,
  orderStatus,
  sampleIdFilter,
  distributerFilter,
  status,
  startDate,
  endDate,
  recentModified
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `sample/principal?status=${status}&currentSampleStatus=${orderStatus}&sampleId=${sampleIdFilter}&distributorId=${distributerFilter}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc&recentModified=${recentModified}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const orderStatusupdate = (formData, id) => {
  return http.put(`order/orderStatus/${id}`, formData, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const shippedfileupload = (data) => {
  return http.post(`order/upload/shippedDoc`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const sampleOrderStatusupdate = (data, id) => {
  return http.put(`sample/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const docList = (productId, categoryId) => {
  return http.get(`api/${productId}/documents/${categoryId}`, {
    headers: {
      ...authHeader(),
    },
  });
};
//-------------------------------------------------------------
const sampleGet = (page, pageSize) => {
  return http.get(
    `sample/allSample?pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=asc`
  );
};
//-------------------------------------------------------------

const getAssoicationLeftSide = (id) => {
  return http.get(`/api/users/principle/unassigned/getall/list?userId=${id}`);
};

const getAssoicationRightSide = (id) => {
  return http.get(`/api/users/principle/assigned/getall?userId=${id}`);
};

const updateAssoication = (data) => {
  return http.put(`api/users/principle/assign`, data, {
    headers: {
      ...authHeader(),
    },
  });
};
const getUserById = (id) => {
  return http.get(`api/users/${id}`);
};

const deleteDistributorOrder = (id) => {
  return http.delete(`order/${id}`);
};

const deleteDistributorSampleOrder = (id) => {
  return http.delete(`sample/${id}`);
};

const galleryGetProductList = (page, pageSize) => {
  return http.get(
    `api/product/new?pageNumber=${page}&pageSize=${pageSize}&sortBy=name&sortDir=dsc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const newTechnicalQuery = (data) => {
  return http.post(`technicalquery/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

const getSeletedPrincipalProductList = (principalId) => {
  debugger;
  return http.get(`/technicalquery/product/principal/${principalId}`);
};
const getProductByProductCode = (productCode) => {
  debugger;
  return http.get(`/technicalquery/productCode?productCode=${productCode}`);
};
//Nutra Connect country list
const countryList = () => {
  return http.get(`nutraCountry/allCountries/list`, {
    headers: {
      ...authHeader(),
    },
  });
};

//country list
const userCountryList = () => {
  return http.get(`nutraCountry/allCountries`, {
    headers: {
      ...authHeader(),
    },
  });
};
const countryStatus = (id, e) => {
  return http.put(`/nutraCountry/country/updateStatus/${id}/${e}`);
};
const countryAdd = (data) => {
  console.log("country_name", data);
  return http.post(`nutraCountry/country/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};

const smtpGet = () => {
  return http.get(`Smtp/get`);
};

// Save SMTP settings
const smtpUpdate = (data) => {
  return http.put(`Smtp/update`, data);
};

//order file upload
const uploadOrderFile = (id, file) => {
  const formData = new FormData();
  formData.append("orderId", id);
  formData.append("files", file);
  console.log("form-data");

  // Use PUT method for file uploads
  return http.put(`order/update/order/doc`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const uploadSampleFile = (id, file) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("files", file);
  console.log("form_data", formData);
  return http.put(`sample/update/sample/doc`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteOrderShipmentDoc = (statusId, docId) => {
  return http.delete(`/order/delete/doc/${statusId}/${docId}`);
};

const deleteSampleShipmentDoc = (statusId, docId) => {
  return http.delete(`/sample/delete/doc/${statusId}/${docId}`);
};

const addNewOrderDocs = (formData) => {
  return http.put(`/order/orderStatus/add/docs`, formData, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

const addNewSampleDocs = (formData) => {
  return http.put(`/sample/orderStatus/add/docs`, formData, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};

const ManagerList = (page, pageSize, roleVeiw) => {
  return http.get(
    `/api/users/managers?role=${roleVeiw}&pageNumber=${page}&size=${pageSize}&sortBy=Id&sortDir=desc`
  );
};

const AddManager = (data) => {
  return http.post(`/api/users/manager`, data);
};

const getModules = () => {
  return http.get(`/module/`, {
    headers: {
      ...authHeader(),
    },
  });
};

const userModules = (userId) => {
  return http.get(`/module/${userId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const assignModule = (data) => {
  return http.put(`access/assign-modules`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

const updateModuleAccess = (data) => {
  return http.put(`/access/assign-modules`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

const UserServices = {
  addNewSampleDocs,
  addNewOrderDocs,
  getAllUsers,
  galleryGetProductList,
  getSeletedPrincipalProductList,
  getProductByProductCode,
  getCurrencyList,
  adduser,
  updateUser,
  deleteUser,
  userBlock,
  galleryGet,
  galleryGetPrincipal,
  galleryPost,
  updateGalleryPost,
  techDocDelete,
  deleteProduct,
  categoriesGet,
  addTechDoc,
  orderGet,
  newOrder,
  updateOrder,
  searchPrinciple,
  adminOrderGet,
  subadminOrderGet,
  adminPaymentGet,
  subAdminPaymentGet,
  paymentApproved,
  approvedPaymentPrincipal,
  paymentDistributor,
  newPayment,
  updatePayment,
  deletePayment,
  PaymentStatusupdate,
  complaintDistributor,
  complaintAdmin,
  subComplaintAdmin,
  complaintPrinciple,
  newComplaint,
  updateComplaint,
  complaintApproved,
  complaintStatusupdate,
  deleteComplaint,
  technicalEnquiryDistributor,
  technicalEnquiryAdmin,
  technicalEnquirySubAdmin,
  technicalEnquiryPrinciple,
  technicalEnquiryApproved,
  newTechnicalQuery,
  technicalEnquiryUpdate,
  technicalEnquiryDelete,
  technicalEnquiryStatusupdate,
  dcNoteAdmin,
  dcNoteSubAdmin,
  dcNoteSave,
  dcNoteUpdate,
  dcNotedelete,
  dcNoteApproved,
  dcNoteDistributor,
  dcNotePrinciple,
  dcNoteStatusupdate,
  salesPipelineDistributor,
  salesPipelinePrinciple,
  salesPipelineAdmin,
  salesPipelineSubAdmin,
  salesPipelineSave,
  salesPipelineUpdate,
  salesPipelineApproved,
  salesPipelineExcelUpload,
  salesPipelineStatusUpdate,
  budgetDistributor,
  newBudget,
  budgetAdmin,
  budgetSubAdmin,
  budgetPrinciple,
  BudgetUpdate,
  budgetApproved,
  budgetStatusupdate,
  budgetExcelDownload,
  budgetExcelUpload,
  forecastExcelUpload,
  deleteBudget,
  orderApproved,
  sampleOrderApproved,
  approvedOrderPrincipal,
  orderStatusupdate,
  shippedfileupload,
  sampleOrderStatusupdate,
  sampleGet,
  getProductDocCategory,
  getAssoicationLeftSide,
  getAssoicationRightSide,
  updateAssoication,
  getUserById,
  deleteDistributorOrder,
  docList,
  techDocGalleryDelete,
  adminSampleOrderGet,
  subAdminSampleOrderGet,
  sampleOrderGet,
  approvedSampleOrderPrincipal,
  newSampleOrder,
  deleteDistributorSampleOrder,
  updateSampleOrder,
  countryList,
  userCountryList,
  countryStatus,
  countryAdd,
  smtpGet,
  smtpUpdate,
  uploadOrderFile,
  uploadSampleFile,
  deleteOrderShipmentDoc,
  deleteSampleShipmentDoc,
  ManagerList,
  AddManager,
  getModules,
  userModules,
  assignModule,
  updateModuleAccess,
};
export default UserServices;
