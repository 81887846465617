import { useEffect, useState } from "react";
import UserServices from "../services/UserServices";
import ServerSideTable from "./serverSideTable";
import {
  Autocomplete,
  Box,
  CircularProgress,
  LinearProgress,
  TextField,
  colors,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import "./style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ProductModal from "./productModal";
import AddTechDoc from "./addTechDoc";

import jwt_decode from "jwt-decode";
import GallryGrid from "./gallryGrid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DistributorServices from "../services/DistributorServices";
import AdminServices from "../services/AdminServices";
// import { CurrencyExchangeSharp } from "@mui/icons-material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Gallery() {
  const [gellaryData, setGellaryData] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [principalFilter, setPrincipalFilter] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState("");
  const [productNameFilter, setProductNameFilter] = useState("");
  const [productCodeFilter, setProductCodeFilter] = useState("");
  const [currency, setCurrency] = useState([]);
  const [currencyFilter, setCurrencyFilter] = useState("");
  const [message, setMessage] = useState();

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  const id = jwtDecode ? jwtDecode.Id : null;
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //-------------Get categoriesList API------------
  function getCategoriesList() {
    // debugger;
    console.log("categoriesGet");
    UserServices.categoriesGet()
      .then((respons) => {
        console.log("categoriesGet", respons.data.data);
        setCategoriesList(respons.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //------------add currecy list----------------

  const getCurrecyList = () => {
    debugger;
    UserServices.getCurrencyList()
      .then((response) => {
        try {
          if (response.status === 200) {
            const data = response.data;
            console.log("currency_data_gallery", data);
            setCurrency(data);
          } else {
            throw new Error("Failed to fetch currency list");
          }
        } catch (error) {
          console.error("Error fetching currency list:", error);
          // Handle error here, e.g., display an error message to the user
        }
      })
      .catch((error) => {
        console.error("Error fetching currency list:", error);
        // Handle error here, e.g., display an error message to the user
      });
  };

  //-------------Get Users API------------
  function getGellaryData(e) {
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    setLoading(true);

    UserServices.galleryGet(
      page,
      pageSize,
      categoriesFilter,
      productNameFilter,
      productCodeFilter,
      principalFilter
      // currencyFilter
    ).then((response) => {
      console.log(response.data);
      const { content, totalPages, totalElements, data } = response.data;
      setGellaryData(content);
      setTotalItems(totalElements);
      setCount(totalPages);
      setLoading(false);
    });
  }
  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(() => {
    getGellaryData();
    setFileCount(0);
  }, []);
  useEffect(() => {
    getGellaryData();
  }, [categoriesFilter, productNameFilter, productCodeFilter, principalFilter]);

  useEffect(() => {
    getGellaryData();
  }, [page, pageSize]);

  //get Currency List
  useEffect(() => {
    getCurrecyList();
  }, []);
  const columns = [
    {
      field: "productCode",
      headerName: "Product Code",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Product Name",
      width: 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "subCategory",
      headerName: "Product Category",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.subCategory.name;
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row?.currency?.symbol;
      },
    },

    {
      field: "price",
      headerName: "Price",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        //  console.log("update", param)
        return (
          <>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                updateHandleShow(param.row);
              }}
            >
              <i className="bi bi-pencil"></i>
            </button>
            <button
              type="button"
              className="btn btn-primary mx-1"
              onClick={() =>
                deletehandleClickOpen(param.row.id, param.row.name)
              }
            >
              <i className="bi bi-trash text-danger"></i>
            </button>
            <AddTechDoc id={param.row.id} load={getGellaryData} />
            <ProductModal
              item={param.row}
              principle={role == "ROLE_CLIENT" ? true : false}
              load={getGellaryData}
            />
          </>
        );
      },
    },
  ];
  //-----------------Add Product----------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [validated, setValidated] = useState(false);

  //---------------------Input- multi-File-Function--------------------
  let state = {};
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };

  //Add product Form Data
  const [category, setCategory] = useState("");
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [description, setDescription] = useState();
  const [files, setFiles] = useState();

  function postAddProduct() {
    debugger;
    if (fileCount === 0) {
      setMessage("Fill all the required fields");
      setOpen(true);
    } else {
      if (
        productName !== "" &&
        productPrice !== "" &&
        productCode !== "" &&
        currencyFilter !== "" &&
        category !== ""
      ) {
        setLoad(true);
        var formData = new FormData();
        formData.append("name", productName);
        formData.append("price", productPrice);
        formData.append("productCode", productCode);
        formData.append("currencyId", currencyFilter);
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
          console.log("state dd", files[i]);
        }

        formData.append("subCategoryId", category);
        formData.append("description", description);
        console.log("formData", files, formData);

        const formDataObject = {};

        // Iterate over the formData object and populate the formDataObject
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        // Now you can stringify formDataObject
        console.log(JSON.stringify(formDataObject));

        UserServices.galleryPost(formData)
          .then((response) => {
            console.log("galleryPost", response);
            setShow(false);
            setLoad(false);
            getGellaryData();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setMessage("Fill all required fields");
        setOpen(true);
      }
    }
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      postAddProduct();
      getGellaryData();
    }

    setValidated(true);
  };

  const [val, setVal] = useState("");

  const numberValidated = (e) => {
    const regex = /^[0-9]*\.?[0-9]*$/;
    console.log("numberValidated", regex.test(e.target.value));
    if (e.target.value === "" || regex.test(e.target.value)) {
      setVal(e.target.value);
    }
  };
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteUser, setDeleteUser] = useState("");

  //-------------------------delete product functionality------------------------
  const deletehandleClickOpen = (id, name) => {
    setDeleteId(id);
    setDeleteUser(name);
    setOpenDelete(true);
  };

  const deletehandle = () => {
    UserServices.deleteProduct(deleteId).then((response) => {
      console.log(response);
      setOpenDelete(false);
      getGellaryData();
    });
  };

  const deletehandleClose = () => {
    setOpenDelete(false);
  };

  //----------------Update Product--------------------------------
  const [updateShow, setUpdateShow] = useState(false);

  const updateHandleClose = () => {
    setUpdateShow(false);
    setDescription("");
    setVal("");
  };
  const updateHandleShow = (param) => {
    debugger;
    console.log("param", param);
    setUpdateShow(true);
    setProductId(param.id);
    setProductName(param.name);
    setProductCode(param.productCode);
    setVal(param.price);
    setDescription(param.description);
    setCategory(param.subCategory.id);
    setCurrencyFilter(param?.currency?.id);

    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  function postUpdateProduct() {
    debugger;
    // Check if all required fields are filled
    if (
      productName !== "" &&
      val !== "" &&
      productCode !== "" &&
      currencyFilter !== ""
    ) {
      setLoad(true);
      var formData = new FormData();
      formData.append("name", productName);
      formData.append("price", val);
      formData.append("productCode", productCode);
      formData.append("currencyId", currencyFilter);

      // Check if files are uploaded
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      } else {
        setMessage("Please Upload File");
        setOpen(true);
        return; // Exit the function if files are not uploaded
      }

      formData.append("subCategoryId", category);
      formData.append("description", description);
      console.log("formData", files, formData);

      // Convert formData to JSON for logging purposes
      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      console.log(JSON.stringify(formDataObject));

      // Send the formData to the API
      UserServices.updateGalleryPost(formData, productId)
        .then((response) => {
          setShow(false);
          setLoad(false);
          getGellaryData();
          setUpdateShow(false);
          setVal("");
          setDescription("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMessage("Fill all the required fields");
      setOpen(true);
      return;
    }
  }

  const [assignedPrincipalList, setAssignedPrincipalList] = useState([]);
  const [principalselect, setPrincipalselect] = useState("");

  //-------------Get principalList API------------
  function getAssignedPrincipalById() {
    DistributorServices.getAssignedPrincipalById(id).then((respons) => {
      console.log("respons.data.data.assignedPrinciples", respons.data.data);
      setAssignedPrincipalList(respons.data.data);
      setPrincipalselect(respons?.data?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [principals, setPrincipals] = useState([]);

  function getAllPrincipal() {
    AdminServices.getAllPrincipal().then((respons) => {
      setPrincipals(respons.data);
      setPrincipalselect(respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  useEffect(() => {
    getAssignedPrincipalById();
    getAllPrincipal();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row d-flex justify-content-between mb-2">
                <div class="col-lg-6">
                  <h3 class="mb-0 dash-heading">Gallery</h3>
                </div>
                <div class="col-lg-6 text-end">
                  {role == "ROLE_CLIENT" ? (
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={handleShow}
                    >
                      <i class="fa fa-plus"></i> Add New Product
                    </button>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="row my-4">
                <div className="row">
                  {role == "ROLE_DISTRIBUTER" ? (
                    <div className="col">
                      <div className="form-group label-figma">
                        <Autocomplete
                          id="principalfilter"
                          sx={{ width: "100%" }}
                          options={assignedPrincipalList}
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            if (value) {
                              setPrincipalFilter(value.id);
                            } else {
                              setPrincipalFilter("");
                            }
                            console.log("categoriesFilter", principalFilter);
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search by Principal"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "Search by Principal", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  ) : role == "ROLE_ADMIN" ? (
                    <div className="col">
                      <div className="form-group label-figma">
                        <Autocomplete
                          id="principalfilter"
                          sx={{ width: "100%" }}
                          options={principals}
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            if (value) {
                              setPrincipalFilter(value.id);
                            } else {
                              setPrincipalFilter("");
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search by Principal"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "Search by Principal", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="col">
                    <div className="form-group label-figma">
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        options={categoriesList}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            setCategoriesFilter(value.id);
                          } else {
                            setCategoriesFilter("");
                          }
                          console.log("categoriesFilter", categoriesFilter);
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search by Category"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group label-figma">
                      <TextField
                        id="outlined-basic"
                        label="Search by Product Code"
                        variant="outlined"
                        value={productCodeFilter}
                        onChange={(e) => setProductCodeFilter(e.target.value)}
                        sx={{ width: "100%" }}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="from-group label-figma">
                      <TextField
                        id="outlined-basic"
                        label="Search by Product Name"
                        variant="outlined"
                        value={productNameFilter}
                        onChange={(e) => setProductNameFilter(e.target.value)}
                        sx={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                {console.log("galleryGetPrincipal", gellaryData)}
                {(role == "ROLE_CLIENT" && count > 0) ? (
                  <ServerSideTable
                    pageSize={10}
                    page={0}
                    count={count}
                    functionName={getGellaryData}
                    data={gellaryData}
                    columns={columns}
                    loading={loading}
                    idname="id"
                  />
                ) : (role == "ROLE_DISTRIBUTER" || role == "ROLE_ADMIN" || role == "ROLE_SUB_ADMIN") ? (
                  <>
                    <div className="row my-3 ">
                      <div className="col">
                        <div className="">
                          <div className="card gallery">
                            <div className="card-body">
                              <div className="row">
                                {gellaryData.map((item) => (
                                  <GallryGrid item={item} />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <p>
                        <b>No Data Available {gellaryData.length},{role},{count}</b>
                      </p>
                    )}
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size={"lg"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Product</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Product Category</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categoriesList.map((item) => (
                    <>
                      <option value={item.id}>{item.name}</option>
                      {/* <>
                        {item.subCategories.map((subItem) => (
                          <option className="option_child" value={subItem.id}>
                            {subItem.name}
                          </option>
                        ))}
                      </> */}
                    </>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Product Name"
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Product Code</b>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Product Code"
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Currency</b>
                </Form.Label>
                <Form.Select
                  required
                  type="text"
                  placeholder="Enter Currency"
                  onChange={(e) => {
                    setCurrencyFilter(e.target.value);
                  }}
                >
                  <option value="">Select Currency</option>
                  {currency?.map((option) => (
                    <option key={option.currency} value={option.id}>
                      {`${option.currency}(${option.symbol})`}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom03">
                <Form.Label>
                  <b>Price</b>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Price"
                  value={val}
                  onChange={(e) => {
                    setProductPrice(e.target.value);
                    numberValidated(e);
                  }}
                />
              </Form.Group>
            </Row>
            <Row style={{ height: "50%" }}>
              <Col>
                <Form.Label>
                  <b> Special Requirements</b>
                </Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  config={editorConfiguration}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    //    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      // multiple
                      accept="image/jpeg, image/jpg, image/png"
                    //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload Photos</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ------------------------------------ Update Product-------------------- */}
      <Modal
        show={updateShow}
        onHide={updateHandleClose}
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Product</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Product Category</b>
                </Form.Label>
                <Form.Select onChange={(e) => setCategory(e.target.value)}>
                  {categoriesList.map((item) => (
                    <>
                      <option value={item.id} selected={category == item.id}>
                        {item.name}
                      </option>
                    </>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Product Name"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Product Code</b>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={productCode}
                  placeholder="Enter Product Code"
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Currency</b>
                </Form.Label>
                <Form.Select
                  required
                  type="text"
                  placeholder="Enter Currency"
                  value={currencyFilter}
                  onChange={(e) => {
                    setCurrencyFilter(e.target.value);
                  }}
                >
                  <option value=""> Select Currency</option>
                  {currency?.map((option) => (
                    <option key={option.currency} value={option.id}>
                      {`${option.currency}(${option.symbol})`}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom03">
                <Form.Label>
                  <b>Price</b>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Price"
                  value={val}
                  onChange={(e) => {
                    setProductPrice(e.target.value);
                    numberValidated(e);
                  }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Label>
                  <b> Special Requirements</b>
                </Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  config={editorConfiguration}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    //    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      // multiple
                      accept="image/jpeg, image/jpg, image/png"
                    //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={updateHandleClose}>
            Close
          </Button>
          <Button variant="primary cursor-pointer" onClick={postUpdateProduct}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          borderRadius: "10px",
          maxWidth: "80%", // Set maximum width as a percentage of the viewport width
          width: "auto", // Allow the width to adjust based on content
          left: "13%",
          top: "5%", // Distance from top as a percentage of the viewport height
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ borderBottom: "1px solid #ccc" }}
        >
          {`Delete Product "${deleteUser}"`}
        </DialogTitle>
        <DialogContent
          style={{ padding: "20px", margin: "auto", fontSize: "16px" }}
        >
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid #ccc" }}>
          <Button onClick={deletehandleClose}>Cancel</Button>
          <Button onClick={deletehandle} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Gallery;
