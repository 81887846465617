import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import dayjs from "dayjs";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

function Docread(prop) {
  const [readshow, setreadshow] = useState(false);
  const readHandleClose = () => setreadshow(false);
  const readHandleShow = () => setreadshow(true);
  //const url = prop.doc[0].documentUrl;
  // console.log(prop.doc[0].documentUrl)
  const data = prop.data.complaintStatuses;
  const handleDownload = async (id, documentUrl) => {
    const fileType = documentUrl.split(".").pop();
    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Complaints-${id}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const [fileshow, setFileshow] = useState(false);
  const [shippedDocList, setShippedDocList] = useState([]);
  const [shippedDocname, setShippedDocname] = useState();
  const [shippedDocdate, setShippedDocdate] = useState();
  const filehandleClose = () => setFileshow(false);
  const filehandleShow = (doc, name, date) => {
    setFileshow(true);
    setShippedDocList(doc);
    setShippedDocname(name);
    setShippedDocdate(date);
  };

  const handleDownloads = async (name, fileType, documentUrl) => {
    //const fileType = documentUrl.split(".")[4]
    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();
      console.error("downloading file:", documentUrl.split("."));
      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={readHandleShow}
      >
        {" "}
        <i className="bi bi-eye"></i>
      </button>
      <Modal
        show={readshow}
        size="lg"
        onHide={readHandleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>#{prop.data.id} Complaint Details</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body><div dangerouslySetInnerHTML={{ __html: prop.data }}></div>
                            <br/>
                            <a href={`${url}`} target="_blank"><img src="/assets/images/pdf.png" class="" width="40px" height="auto" alt=""/></a>
                            </Modal.Body> */}
        <Modal.Body>
          <Form noValidate>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Control
                  value={prop.data.userPrincipal.name}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Order ID</b>
                </Form.Label>
                <Form.Control value={prop.data.orderId} disabled></Form.Control>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Label>
                  <b>Batch No</b>
                </Form.Label>
                <Form.Control value={prop.data.batchNo} disabled></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>complaint</b>
                  </Form.Label>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: prop.data.complaints,
                    }}
                    style={{
                      maxWidth: "800px", // Set maximum width
                      maxHeight: "200px", // Set maximum height
                      overflowY: "auto", // Enable vertical scrolling
                      border: "1px solid #ccc", // Add a border for clarity
                      padding: "5px", // Add padding for aesthetics
                      wordWrap: "break-word", // Allow word wrapping
                      marginBottom: "15px",
                    }}
                  ></div>
                  {prop?.data?.complaintDocumentations?.length > 0 && (
                    <div>
                      <p>
                        <Form.Label>
                          <b>Uploaded Documents</b>
                        </Form.Label>
                      </p>
                      <a
                        onClick={() =>
                          handleDownload(
                            prop?.data?.id,
                            prop?.data?.complaintDocumentations[0]?.documentUrl
                          )
                        }
                      >
                        <img
                          src="/assets/images/pdf.png" //changed
                          className="download"
                          width="40px"
                          height="auto"
                          alt=""
                        />
                      </a>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <Form.Label>
                  <b>Track Status</b>
                </Form.Label>
                <div class="history-tl-container">
                  <ul class=" tl virtical">
                    {data?.map((item) => (
                      <li class="tl-item" ng-repeat="item in retailer_history">
                        <div class="timestamp">
                          {item.order_Status_Enum == "SHIPPED" ? (
                            <span
                              onClick={() =>
                                filehandleShow(
                                  item.shippedDocumentations,
                                  item.order_Status_Enum,
                                  item.updatedAt
                                )
                              }
                            >
                              <i class="fa fa-files-o"></i>{" "}
                              {item.shippedDocumentations.length} Files
                            </span>
                          ) : null}
                          {dayjs(item.updatedAt).format("DD MMM YYYY")}
                          <br /> {dayjs(item.updatedAt).format("hh : mm A")}
                        </div>
                        <div class="item-title">{item.order_Status_Enum}</div>
                        <div class="item-detail">{item.comment}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={readHandleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={fileshow}
        onHide={filehandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="shippedDocList"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {shippedDocname} - {dayjs(shippedDocdate).format("DD MMM YYYY")} -{" "}
            {dayjs(shippedDocdate).format("hh : mm A")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="orderShipList">
            {shippedDocList.length > 0 ? (
              shippedDocList.map((item) => (
                <li>
                  <div className="filename">
                    {item.name}.{item.fileType}
                  </div>
                  <button
                    onClick={(e) =>
                      handleDownloads(
                        item.name,
                        item.fileType,
                        item.documentUrl
                      )
                    }
                  >
                    <img src="/assets/images/download.png" />
                  </button>
                </li>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "150px",
                }}
              >
                <img
                  src="/assets/images/no-document.png"
                  style={{ width: "100px" }}
                />
                <p style={{ margin: 0 }}>
                  <b>No Documents</b>
                </p>
              </div>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={filehandleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Docread;
