import http from "../../httpcommon";
import authHeader from "../../services/auth-header";

const distributerForecast = (
  page,
  pageSize,
  orderStatus,
  forecastId,
  principalFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  return http.get(
    `forecast/distributor?currentStatus=${orderStatus}&forecastId=${forecastId}&principalId=${principalFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const principalForecast = (
  page,
  pageSize,
  orderStatus,
  forecastId,
  distributerFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("counter here");
  console.log("principal-forecast", page, pageSize);
  return http.get(
    // `forecast/approvedbyadmin?currentStatus=${orderStatus}&forecastId=${forecastId}&distributorId=${distributerFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    `forecastDoc/principal`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const adminForecast = (
  page,
  pageSize,
  orderStatus,
  forecastId,
  principalFilter,
  distributerFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `forecast/admin?currentStatus=${orderStatus}&forecastId=${forecastId}&principalId=${principalFilter}&distributorId=${distributerFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const subAdminForecast = (
  page,
  pageSize,
  orderStatus,
  forecastId,
  principalFilter,
  distributerFilter,
  status,
  recentModified,
  startDate,
  endDate
) => {
  console.log("getUsers galleryGet", page, pageSize);
  return http.get(
    `forecast/manager?currentStatus=${orderStatus}&forecastId=${forecastId}&principalId=${principalFilter}&distributorId=${distributerFilter}&status=${status}&recentModified=${recentModified}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=${pageSize}&sortBy=id&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const saveForecast = (data) => {
  return http.post(`forecast/save`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const getAnnualBudgetPrice = (productCode) => {
  return http.get(`/budget/get/annual/budget/${productCode}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const ForecastUpdate = (data, id) => {
  return http.post(`forecast/update/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const deleteForecast = (id) => {
  return http.delete(`forecast/${id}`);
};
const forecastApproved = (data, id) => {
  console.log("forecastApproved", id);
  return http.put(`forecast/approved/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
};
const forecastStatusupdate = (data, id) => {
  return http.put(`forecast/status/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
};

const ForecastService = {
  distributerForecast,
  principalForecast,
  adminForecast,
  subAdminForecast,
  saveForecast,
  ForecastUpdate,
  deleteForecast,
  forecastApproved,
  forecastStatusupdate,
  getAnnualBudgetPrice,
};
export default ForecastService;
