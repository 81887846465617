import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import dayjs from "dayjs";
import { Form } from "react-bootstrap";
import UserServices from "../../services/UserServices";
import { Chip } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Portal } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function OrderStatus(prop) {
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState();
  const handleClose = () => setShow(false);
  const [statusValue, setStatusValue] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [comment, setComment] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  let state = {};
  const accept =
    ".docx, .doc, .rtf, .txt, .pdf, .html, .odt, .docm, .dotx, .dotm, .odp, .odg, .odc, .odf, .wps, .xlsx, .xls, .xlsm, .csv, .tsv, .ods, .xltx, .xltm, .xlsb, .xlw, .txt, .prn, .slk, .dbf, .dif, .ods, .json, .pptx, .ppt, .pptm, .pps, .ppsx, .potx, .potm, .ppa, .ppam, .xml, .odp, .odg, .gif, .jpg, .jpeg, .png, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .ico, .svg, .webp, .webm, .mp4";

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  setTimeout(() => {
    const inputFile = document.getElementById("multi-file-input");
    const fileList = document.getElementById("fileList");
    //  console.log("fileList", fileList.children[1])

    function updateState(newState) {
      state = { ...state, ...newState };
    }
    if (inputFile) {
      inputFile.addEventListener("change", function (e) {
        let files = inputFile.files;
        setFileCount(files.length);
        let filesArr = Array.from(files);

        updateState({ files: files, filesArr: filesArr });
        //    setFile(state)
        if (Object.keys(state).length !== 0) {
          const stateValue = state.filesArr;
          setFiles(stateValue);
        }
        renderFileList();
      });
    }
    let curArr;
    if (inputFile) {
      fileList.addEventListener("click", function (e) {
        let key = e.target.parentElement.getAttribute("key");

        if (state.filesArr !== undefined) {
          curArr = state.filesArr.slice();
          curArr.splice(key, 1);
          updateState({ filesArr: curArr });
          setFileCount(state.filesArr.length);
          const statefilesArr = state.filesArr;
          setFiles(statefilesArr);
          renderFileList();
        }
      });
    }

    function renderFileList() {
      let fileMap = state.filesArr.map((file, index) => {
        let suffix = "bytes";
        let size = file.size;
        if (size >= 1024 && size < 1024000) {
          suffix = "KB";
          size = Math.round((size / 1024) * 100) / 100;
        } else if (size >= 1024000) {
          suffix = "MB";
          size = Math.round((size / 1024000) * 100) / 100;
        }

        return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
      });

      fileList.children[1].innerHTML = fileMap.join("");
    }
  }, [1000]);
  const data = prop.data;
  // param.row.orderStatus[param.row.orderStatus.length - 1]
  const dataLength = data.length - 1;
  const lastUpdatevalue = prop?.data[dataLength].order_Status_Enum;
  const lastUpdate =
    lastUpdatevalue.indexOf("_") > 0 == true
      ? `${lastUpdatevalue.split("_")[0]} ${lastUpdatevalue.split("_")[1]}`
      : lastUpdatevalue;
  let dataJson = {
    comment: comment,
    order_Status_Enum: statusValue,
  };
  // function shippedfileupload() {
  //   const formData = new FormData();
  //   formData.append("orderId", prop.id);
  //   if (files.length == undefined) {
  //     console.log("undefined");
  //   } else {
  //     for (let i = 0; i < files.length; i++) {
  //       formData.append("documents", files[i]);
  //     }
  //   }
  //   console.log("formData", formData);
  //   UserServices.shippedfileupload(formData).then((response) => {
  //     console.log(response);
  //     setFileCount(0);
  //     prop.load();
  //   });
  // }
  function orderStatus() {
    const formData = new FormData();
    formData.append("comment", comment);
    formData.append("order_Status_Enum", statusValue);
    if (files !== undefined) {
      if (files.length == undefined) {
        console.log("undefined");
      } else {
        for (let i = 0; i < files.length; i++) {
          formData.append("documents", files[i]);
        }
      }
    }
    console.log("formData", formData);
    UserServices.PaymentStatusupdate(dataJson, prop.id)
      .then((response) => {
        setMessage("Payment status changed successfully");
        setOpen(true);
        setSeverity("success");
        prop.load();
      })
      .catch((error) => {
        setMessage("Something went wrong!");
        setOpen(true);
        setSeverity("error");
      });
  }
  const colors =
    lastUpdate == "PENDING"
      ? "#FF7272"
      : lastUpdate == "APPROVED"
      ? "#4CAF50 "
      : lastUpdate == "DELIVERED"
      ? "#6B8E23"
      : lastUpdate == "SHIPPED"
      ? "#3498DB"
      : lastUpdate == "REJECTED"
      ? "#dc3545"
      : lastUpdate == "PRODUCTION"
      ? "#B77E00  "
      : "#73a580";
  // console.log("OrderStatus DD", lastUpdate, colors);

  const [fileshow, setFileshow] = useState(false);
  const [shippedDocList, setShippedDocList] = useState([]);
  const [shippedDocname, setShippedDocname] = useState();
  const [shippedDocdate, setShippedDocdate] = useState();
  const filehandleClose = () => setFileshow(false);
  const filehandleShow = (doc, name, date) => {
    setFileshow(true);
    setShippedDocList(doc);
    setShippedDocname(name);
    setShippedDocdate(date);
  };

  const handleDownload = async (name, fileType, documentUrl) => {
    //const fileType = documentUrl.split(".")[4]
    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();
      console.error("downloading file:", documentUrl.split("."));
      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.${fileType}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <Chip
        label={lastUpdate == undefined ? "PENDING" : lastUpdate}
        // color={`${colors.toString()}`}
        onClick={handleShow}
        style={{ width: "100%", backgroundColor: colors, color: "#fff" }}
      />
      {/* <Button
        variant="primary"
        onClick={handleShow}
        className="btn btn-primary mx-1"
      >
        <i className="bi bi-pencil"></i>
        {lastUpdate}
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="history-tl-container">
            {prop.role == "ROLE_CLIENT" || prop.role == "ROLE_SUB_ADMIN" ? (
              <>
                <Form.Select
                  onChange={(e) => setStatusValue(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="">Please select payment Current Status</option>
                  <option value="REJECTED">Reject</option>
                  <option value="APPROVED">Approved</option>
                  <option value="CREDIT_RECEIVED">Credit Received</option>
                </Form.Select>
                <Form.Control
                  className="mt-2 mb-2"
                  placeholder="Enter Comment Here"
                  as="textarea"
                  style={{ height: "100px" }}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={statusValue == ""}
                ></Form.Control>
                {statusValue == "SHIPPED" ? (
                  <>
                    <div className="file-input">
                      <label className="file-input__label" for="file-input">
                        <input
                          type="file"
                          id="multi-file-input"
                          class="file-input__input"
                          multiple
                          accept={accept}
                          //onChange={(e) => setFile(e.target.files)}
                        />
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="upload"
                          class="svg-inline--fa fa-upload fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                          ></path>
                        </svg>
                        <span>Upload file</span>
                      </label>
                    </div>
                    <br />
                    <div
                      className="files"
                      id="fileList"
                      style={
                        fileCount > 0
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <h5>Files Selected</h5>
                      <ul className="Filelisting"></ul>
                    </div>
                  </>
                ) : null}
                <Button
                  className="btn btn-dark w-100 mt-2"
                  onClick={orderStatus}
                  disabled={statusValue == ""}
                >
                  Update
                </Button>
                <hr />
              </>
            ) : null}
            {prop.role == "ROLE_ADMIN" ? (
              <>
                <Form.Select
                  onChange={(e) => setStatusValue(e.target.value)}
                  aria-label="Default select example"
                >
                  {/* <option disabled>Update Status</option> */}
                  <option value="PENDING">Select Status</option>
                  <option value="REJECTED">Reject</option>
                  {/* <option value="PRODUCTION">Production</option>
                  <option value="DELIVERED">Delivered</option>
                  <option value="SHIPPED">Shipped</option> */}
                </Form.Select>
                <Form.Control
                  className="mt-2"
                  placeholder="Enter Comment Here"
                  as="textarea"
                  style={{ height: "100px" }}
                  disabled={statusValue == ""}
                  onChange={(e) => setComment(e.target.value)}
                ></Form.Control>
                <Button
                  className="btn btn-dark w-100 mt-2"
                  onClick={orderStatus}
                  disabled={statusValue == ""}
                >
                  Update
                </Button>
                <hr />
              </>
            ) : null}
            <p>{lastUpdate == undefined ? " There no any Action" : null}</p>
            <ul class=" tl">
              {data?.map((item) => (
                <li class="tl-item" ng-repeat="item in retailer_history">
                  <div class="timestamp">
                    {dayjs(item.updatedAt).format("DD MMM YYYY")}
                    <br /> {dayjs(item.updatedAt).format("hh : mm A")}
                  </div>
                  <div class="item-title">
                    {item.order_Status_Enum} &nbsp;
                    {item.order_Status_Enum == "SHIPPED" ? (
                      <span
                        onClick={() =>
                          filehandleShow(
                            item.shippedDocumentations,
                            item.order_Status_Enum,
                            item.updatedAt
                          )
                        }
                      >
                        <i class="fa fa-files-o"></i>{" "}
                        {item.shippedDocumentations.length} Files
                      </span>
                    ) : null}
                  </div>
                  <div class="item-detail">{item.comment}</div>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={fileshow}
        onHide={filehandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="shippedDocList"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {shippedDocname} - {dayjs(shippedDocdate).format("DD MMM YYYY")} -{" "}
            {dayjs(shippedDocdate).format("hh : mm A")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="orderShipList">
            {shippedDocList.length > 0 ? (
              shippedDocList.map((item) => (
                <li>
                  <div className="filename">
                    {item.name}.{item.fileType}
                  </div>
                  <button
                    onClick={(e) =>
                      handleDownload(item.name, item.fileType, item.documentUrl)
                    }
                  >
                    <img src="/assets/images/download.png" />
                  </button>
                </li>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "150px",
                }}
              >
                <img
                  src="/assets/images/no-document.png"
                  style={{ width: "100px" }}
                />
                <p style={{ margin: 0 }}>
                  <b>No Documents</b>
                </p>
              </div>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={filehandleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Portal>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ zIndex: 1500 }} // Ensure Snackbar appears above the modal
        >
          <Alert
            onClose={alertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}

export default OrderStatus;
